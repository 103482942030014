import React from "react"
import { Award, Home, MapPin, Star } from "react-feather"

const he = [{
  title: 'Enhanced Clean',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: Star,
},
{
  title: 'Great Location',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: MapPin,
},
{
  title: 'Pets Allowed',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: Award,
},
{
  title: 'Entire Home',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: Home,
}
]


const GridA = ({ data }) => {
  console.log("GRID A ", data)
  return (
    <div className="grid-a-wrap grid sm:grid-cols-2 grid-cols-1 gap-12 sm:grid-rows-4 md:grid-rows-2 py-12 my-12 px-12">
         {he.map(({title, description, Icon}, i) => (
            <div key={i} className="grid-img-wrap small-grid-card flex gap-4 mb-8">
              <Icon className="mt-0.5" />
              <span>
                <p className="font-bold text-lg">{title}</p>
                <p className="text-gray-400">{description}</p>
              </span>
            </div>
          ))
         }
    </div>
  )
}

export default GridA
