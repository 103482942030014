import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Wifi } from "react-feather"

const dat = [
  {title: 'Lake Access',},
  {title: 'Wifi'},
  {title: 'Private hot tub'},
  {title: 'TV'},
  {title: 'Kitchen'},
  {title: 'Free parking on premises'},
  {title: 'Pets Allowed'},
  {title: 'Security cameras on property'},
]

const Amenities = ({data}) => {
  console.log("Amenities ", data)
  return (
    <div className="grid grid-rows-4 lg:grid-cols-2 gap-8 px-8 my-12 sm:grid-cols-1">
      {dat.map(({title}) => <div key={title} className="flex gap-4">
        <Wifi />
       <p className="text-2xl">{title}</p>
       </div>)}
    </div>
  )
}

export default Amenities
