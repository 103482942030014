import * as React from "react"
import Banner from "../components/Banner/Banner"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import GridA from "../components/GridA/GridA"
import About from "../components/About/About"
import GridB from "../components/GridB/GridB"
import Amenities from "../components/Amenities/Amenities"
import ReviewsRatings from "../components/ReviewsRatings/ReviewsRatings"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title={"Airstays"}/>
    <Banner data={data.banner} />
    <GridA data={data.locationFeatures} />
    <GridB data={data.showcase} extras={data.extras} />
    <About data={data.extras} />
    <Amenities data={data.amenities} />
    <ReviewsRatings data={data.reviews}/>
  </Layout>
)

export const query = graphql`
{
  banner: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "banner"}}}
    sort: {fields: updated_time, order: DESC}
    limit: 1
  ) {
    nodes {
      url
      meta{
        url
      }
    }
  }
  extras: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "extras"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      meta {
        aboutHeading
        aboutDescription
        availabilityColor
      }
    }
  }
  showcase: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "showcase"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
      }
    }
    distinct(field: meta___position)
  }
  amenities: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "amenities"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        title
      }
    }
  }
  reviews: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "reviews"}}}
    sort: {fields: meta___date, order: ASC}
  ) {
    nodes {
      url
      meta {
        position
        url
      }
    }
    distinct(field: meta___position)
  }
  locationFeatures: allMasonContentCreative(
    filter: {meta: {contentType: {eq: "location_features"}}}
    sort: {fields: updated_time, order: ASC}
  ) {
    nodes {
      url
      meta {
        heading
        description
        position
      }
    }
    distinct(field: meta___position)
  }
}
`


export default IndexPage
